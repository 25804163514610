import { Component, Input } from '@angular/core';
import { NgFor } from '@angular/common';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { Locale } from '@parashift/shared/models';

@Component({
  selector: 'locale-badges',
  templateUrl: './locale-badges.component.html',
  imports: [NgFor, FaIconComponent]
})
export class LocaleBadgesComponent {
  @Input() locales: Locale[];
}
